<template>
  <div class="microbialDetection">
    <!-- 头部 -->
    <swipeTop
      :top_img="top_img"
      :ZHtitle="ZHtitle"
      :ENtitle="ENtitle"
    ></swipeTop>
    <!-- 搜索框 -->
    <seachVue></seachVue>
    <!-- 导航标签 -->
    <classifyList
      :checkindex="checkindex"
      :bgcolor="bgcolor"
      :toptype="toptype"
    />
    <!-- 内容 -->
    <div class="listContent">
      <div
        v-bind:style="{ backgroundImage: 'url(' + item.pic + ')' }"
        v-for="(item, index) in subMenuList"
        @click="tofourPage(item)"
        :key="index"
        class="listItem"
      >
        <!-- 标题 -->
        <div class="itemTitle">
          <div class="line"></div>
          <span>{{ item.menuName }}</span>
        </div>
        <!-- 分类 -->
        <div class="label">
          <div v-if="item.subMenuList != []">
            <ul>
              <li v-for="(item1, index1) in item.subMenuList" :key="index1">
                {{ item1.menuName }}
              </li>
            </ul>
          </div>
          <div v-if="item.productList.length > 1">
            <ul >
              <li v-for="(item1, index1) in item.productList" :key="index1">
                {{ item1.productName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="showtext" v-if="showText"><span>数据更新中...</span></div>
    </div>
  </div>
</template>

<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import tag from "@/components/tag";
import classifyList from "@/components/classifyList.vue";
import listVue from "@/components/listVue";
import { genMenuInfo } from "@/api/path";
import { HappyScroll } from "vue-happy-scroll";
import "vue-happy-scroll/docs/happy-scroll.css";
export default {
  components: {
    swipeTop,
    seachVue,
    classifyList,
    HappyScroll,
    tag,
    listVue
  },
  data() {
    return {
      menuId: this.$route.query.menuId, // 获取页面id
      ParentId: -1, // 获取父级id
      top_img: "", // 头部背景图
      ZHtitle: "", // 中文标题
      ENtitle: "", // 英文标题
      openId: "", // 登录
      subMenuList: [],
      showText: false,
      toptype: [], // 导航标签数据
      bgcolor: "",
      checkindex: Number(this.$route.query.menuId), //导航标签选中
      tagList: [
        {
          tid: 0,
          text: "公司介绍",
        },
        {
          tid: 1,
          text: "微生物检测",
        },
        {
          tid: 2,
          text: "过敏原检测",
        },
        {
          tid: 3,
          text: "移植诊断",
        },
      ],
    };
  },
  watch: {
    $route: function (newQuestion, oldQuestion) {
      this.menuId = newQuestion.query.menuId;
      this.checkindex = Number(newQuestion.query.menuId);
      this.getAllMenu();
    },
  },
  mounted() {
    // 监听移动端返回
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  created() {
    this.getAllMenu();
  },
  methods: {
    // 获取数据
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.ZHtitle = res.data.menuName; // 获取中文标题
          this.ENtitle = res.data.menuEnglish; // 获取英文标题
          this.top_img = res.data.background; // 获取头部背景图片
          this.subMenuList = res.data.subMenuList; // 获取子分类
          this.ParentId = res.data.parentId; // 获取父级id
          if (this.ParentId == 5) {
            this.bgcolor = "#00078c";
          }
          this.toptype = res.data.classificationMessageAlways; //获取标签分类
          if (!res.data.subMenuList) {
            this.showText = true;
          } else {
            this.showText = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 四级页面
    tofourPage(item) {
      // 判断是否是品牌介绍页面
      if (item.productList) {
        if (item.productList.length === 1) {
          if (item.menuId === 218 || item.menuId === 219) {
            this.$router.push({
              path: "/levelFivepagePPJS",
              query: { menuId: item.menuId, checkindex: this.checkindex },
            });
          } else {
            if (item.productList[0].fileType === 4) {
              location.href = item.productList[0].fileUrl;
            } else {
              this.$router.push({
                path: "/detailsPage",
                query: {
                  menuid: item.productList[0].productId,
                  checkindex: this.checkindex,
                },
              });
            }
          }
        } else {
          this.$router.push({
            path: "/levelFivePage",
            query: { menuId: item.menuId, checkindex: this.checkindex },
          });
        }
      } else {
        switch (item.menuId) {
          case 195:
          case 196:
          case 197:
            this.$router.push({
              path: "/detailsPage",
              query: {
                menuid: item.subMenuList[0].productList[0].productId,
              },
            });
            break;
          case 199:
          case 108:
          case 200:
            this.$router.push({
              path: "/levelFourPagesCPJS",
              query: { menuId: item.menuId, checkindex: this.checkindex },
            });
            break;
          case 108:
            if (this.openId) {
              this.$router.push({
                path: "/levelFourPagesLoginWXCY",
                query: { menuId: item.menuId, checkindex: this.checkindex },
              });
            } else {
              this.$dialog
                .alert({
                  message: "请先登录",
                })
                .then(() => {
                  this.$router.push({
                    path: "/loginPage",
                  });
                });
            }
            break;
          case 109:
            this.$router.push({
              path: "/levelFourPages",
              query: { menuId: item.menuId, checkindex: this.checkindex },
            });

            break;
          default:
            this.$router.push({
              path: "/levelFourPages",
              query: { menuId: item.menuId, checkindex: this.checkindex },
            });
            break;
        }
      }
    },
    // 返回事件处理
    backButton() {
      if (this.ParentId === 5) {
        this.$router.replace({
          path: "/secondaryPagesHDXX",
          query: { mid: this.ParentId },
        });
      } else if (this.ParentId === 7 || this.ParentId === 8) {
        this.$router.replace({
          path: "/secondaryPagesYYLY",
          query: { mid: this.ParentId },
        });
      } else if (this.ParentId === 4) {
        this.$router.replace({
          path: "/secondaryPagesPPJS",
          query: { mid: this.ParentId },
        });
      } else if(this.ParentId === 9){
        this.$router.replace({
          path: "/",
        });
      } else {
        this.$router.replace({
          path: "/secondaryPages",
          query: { mid: this.ParentId },
        });
      }
    },
  },

  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>

<style lang="scss" scoped>
.microbialDetection {
  height: 100vh;
}
.listContent {
  width: 100%;
  height: 57%;
  overflow-y: scroll;
  background-color: #9b0a12;
  font-family: 'FZZZH',serif;
  .listItem {
    width: 100%;
    height: 23%;
    display: flex;
    background-size: 100% 100%;
    // justify-content: space-between;
    .itemTitle {
      padding: 10px 20px;
      width: 25%;
      // margin-left: 8px;
      color: white;
      .line {
        width: 18%;
        height: 0.8vw;
        margin: 8px 2px;
        background-color: rgb(173, 173, 173);
      }
      span {
        font-size: 3.5vw;
        font-weight: 550;
      }
    }
  }
  .label {
    font-size: 3vw;
    color: #ffffff;
    display: flex;
    width: 75%;
    // padding: 6px 0;
    height: 95%;
    overflow-y: auto;
    position: relative;
    right: 8px;
    margin: 5px 0;
    ul {
      padding-left: 3px;
      list-style: disc;
    }
    li {
      // margin-right: 25px;
      margin-left: 32px;
      list-style-position: outside;
      // list-style-position: outside;
    }
  }
}
.classifyList {
  background-color: #d81722;
  font-size: 3vw;
  color: #fff;
  width: 100%;
  height: 6vh;
  .classList {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    :not(:nth-last-child(1)) {
      border-right: 1px solid #fff;
    }
    .classItem {
      width: 24.7%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        height: 80%;
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.checkSty {
  background-color: #bf545a;
}
.showtext {
  color: #fff;
  font-size: 3vw;
  text-align: center;
  margin-top: 30px;
}
</style>
<style>
</style>
